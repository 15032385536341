import { createRouter, createWebHistory } from 'vue-router'
import NotFoundView from '../views/NotFoundView.vue'
import { nextTick } from 'vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/scheduling-blocks/:id(\\d+)/',
      name: 'scheduling-block',
      // route level code-splitting
      // this generates a separate chunk for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/SchedulingBlockView.vue'),
      meta: {
        title: (route) => {
          return route.params.id
        }
      },
      props: (route) => ({ sbid: route.params.id })
    },
    {
      path: '/scheduling-blocks/:id(\\d+)',
      redirect: (to) => {
        // the function receives the target route as the argument
        // we return a redirect path/location here.
        return { name: 'scheduling-block', params: { id: to.params.id } }
      }
    },
    { path: '/:pathMatch(.*)*', component: NotFoundView }
  ],
  sensitive: true,
  strict: true
})

const DEFAULT_TITLE = 'ARWEN'

router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title ? to.meta.title(to) : DEFAULT_TITLE
  })
})

export default router
